import { Link, PageProps } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { PromiseType } from 'utility-types'
import { getCourseReporting } from 'API/reporting'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { CategoryScale } from 'chart.js'

import { getUserData } from 'API/user'
import Chart from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import { Pie } from 'react-chartjs-2'
import './asset/style.css'
Chart.register(CategoryScale)

const Reporting: React.FC<PageProps> = ({ params: { id: course_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(course_id)
	const [reporting, setReporting] = useState<PromiseType<ReturnType<typeof getCourseReporting>>>()
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const [assigmentName, setAssignmentName] = useState<any>([])
	const [maximum, setMaximum] = useState<any>([])
	const [minimum, setMinimum] = useState<any>([])
	const [average, setAverage] = useState<any>([])
	const [percentage, setPercentage] = useState<any>([])
	const [pass, setPass] = useState<any>([])
	const [submit, setSubmitted] = useState<any>([])
	const [totalStudent, setTotalStudent] = useState<any>(0)
	const [scount, setTotalsub] = useState<any>([])
	const [nscount, setTotalnonsub] = useState<any>([])
	React.useEffect(() => {
		const run = async () => {
			// runAuth()
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])
	useEffect(() => {
		const run = async () => {
			if (viewAs === 'Teacher') {
				const reporting = await getCourseReporting({ course_id, asTeacher: viewAs === 'Teacher' ? true : false })

				// console.log(reporting?.assignments)
				// console.log(reporting)
				setTotalStudent(reporting?.users.length)
				const name: string[] = []
				const avgMarks: number[] = []
				const maxMarks: number[] = []
				const minMarks: any[] = []
				const perc: any[] = []
				const pass: any[] = []
				const submit: any[] = []

				// Assigments
				reporting?.assignments.map((assignment) => {
					let max = 0
					name.push(assignment.name)

					// Average marks assignment
					const averageMarks =
						reporting.assignmentSubmissions
							.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
							.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) / (reporting.users.length ?? 1)
					avgMarks.push(averageMarks)

					// Minimum marks assignment
					let min = assignment.marks
					if (assignment.grade_type === 'grades') {
						min = 0
					} else {
						min && min !== 0
							? reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList?.forEach((elem) => {
										// console.log(assignment.name, elem.givenMarks)
										if (elem.givenMarks && min && min !== 0) {
											if (elem.givenMarks < min) {
												min = elem.givenMarks
											}
										}
										if (!elem.givenMarks) {
											min = 0
										}
									})
							: // .reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
							  // (assignment.marks * (reporting.users.length ?? 1))) *
							  //   100
							  'N/A'
					}
					minMarks.push(min)

					// Maximum marks array
					if (assignment.grade_type === 'grades') {
						max = 0
					} else {
						reporting.assignmentSubmissions
							.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
							.submissionList?.forEach((elem) => {
								// console.log(assignment.name, elem.givenMarks)
								if (elem.givenMarks) {
									if (elem.givenMarks >= max) {
										max = elem.givenMarks
									}
								}
							})
					}
					maxMarks.push(max)

					// Percentage
					const Percentage =
						assignment.marks && assignment.marks !== 0
							? (reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
									(assignment.marks * (reporting.users.length ?? 1))) *
							  100
							: 'N/A'
					perc.push(Percentage)

					// Passed
					const passed =
						assignment.marks && assignment.marks !== 0
							? reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList?.filter((elem) => {
										if (elem.givenMarks && assignment.marks && assignment.marks !== 0) {
											if (elem.givenMarks / assignment.marks >= 0.5) {
												return 1
											} else {
												return 0
											}
										}
										return 0
									}).length
							: 'N/A'
					pass.push(passed)

					// submitted
					const data = reporting.assignmentSubmissions
						.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
						.submissionList?.filter((elem) => {
							// console.log(assignment.name, elem.submitted)
							if (elem.submitted === true) {
								return 1
							} else {
								return 0
							}
						}).length
					submit.push(data)
				})
				// Quizes

				// average quiz marks
				reporting?.quizzes.map((quiz) => {
					name.push(quiz.name)
					const averageMarks =
						reporting.quizSubmissions
							.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
							.submissionList?.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) / (reporting.users.length ?? 1)
					avgMarks.push(averageMarks)

					// minimum quiz marks
					let min = quiz.marks
					min && min !== 0
						? reporting.quizSubmissions
								.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
								.submissionList?.forEach((elem) => {
									if (elem.givenMarks && min && min !== 0) {
										if (elem.givenMarks < min) {
											min = elem.givenMarks
										}
									}
									if (!elem.givenMarks) {
										min = 0
									}
								})
						: 'N/A'
					minMarks.push(min)

					// maximum quiz marks
					let max = 0
					true
						? reporting.quizSubmissions
								.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
								.submissionList?.forEach((elem) => {
									if (elem.givenMarks) {
										if (elem.givenMarks > max) {
											max = elem.givenMarks
										}
									}
								})
						: 'N/A'
					maxMarks.push(max)

					// Percentage
					const Percentage =
						quiz.marks && quiz.marks !== 0
							? (reporting.quizSubmissions
									.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
									.submissionList?.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
									(quiz.marks * (reporting.users.length ?? 1))) *
							  100
							: 'N/A'
					perc.push(Percentage)

					// Passed
					const passed =
						quiz.marks && quiz.marks !== 0
							? reporting.quizSubmissions
									.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
									.submissionList?.filter((elem) => {
										if (elem.givenMarks && quiz.marks && quiz.marks !== 0) {
											if (elem.givenMarks / quiz.marks >= 0.5) {
												return true
											} else {
												return false
											}
										}
										return false
									}).length
							: 'N/A'
					pass.push(passed)

					// submitted
					const data = reporting.quizSubmissions
						.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
						.submissionList?.filter((elem) => {
							if (elem.submitted) {
								return 1
							}
						}).length
					submit.push(data)
				})

				// console.log(maxMarks)
				// console.log(avgMarks)
				// console.log(minMarks)
				// console.log(perc)
				// console.log(pass)
				// console.log(submit)
				setAssignmentName(name)
				setAverage(avgMarks)
				setMaximum(maxMarks)
				setMinimum(minMarks)
				setPercentage(perc)
				setPass(pass)
				setSubmitted(submit)
				// reporting?.assignmentSubmissions.map((marks) => {
				// 	console.log(marks)
				// })
				setReporting(reporting)
			} else {
				const reporting = await getCourseReporting({ course_id, asTeacher: false })
				// console.log(reporting)
				setTotalStudent(reporting?.users.length)
				const name: string[] = []
				const avgMarks: number[] = []
				const maxMarks: number[] = []
				const minMarks: any[] = []
				const perc: any[] = []
				const pass: any[] = []
				const submit: any[] = []

				// Assigments
				reporting?.assignments.map((assignment) => {
					let max = 0
					name.push(assignment.name)

					// Average marks assignment
					const averageMarks =
						reporting.assignmentSubmissions
							.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
							.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) / (reporting.users.length ?? 1)
					avgMarks.push(averageMarks)

					// Minimum marks assignment
					let min = assignment.marks
					if (assignment.grade_type === 'grades') {
						min = 0
					} else {
						min && min !== 0
							? reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList?.forEach((elem) => {
										// console.log(assignment.name, elem.givenMarks)
										if (elem.givenMarks && min && min !== 0) {
											if (elem.givenMarks < min) {
												min = elem.givenMarks
											}
										}
										if (!elem.givenMarks) {
											min = 0
										}
									})
							: // .reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
							  // (assignment.marks * (reporting.users.length ?? 1))) *
							  //   100
							  'N/A'
					}
					minMarks.push(min)
					// console.log(minMarks)

					// Maximum marks array
					if (assignment.grade_type === 'grades') {
						max = 0
					} else {
						reporting.assignmentSubmissions
							.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
							.submissionList?.forEach((elem) => {
								// console.log(assignment.name, elem.givenMarks)
								if (elem.givenMarks) {
									if (elem.givenMarks >= max) {
										max = elem.givenMarks
									}
								}
							})
					}
					maxMarks.push(max)

					// Percentage
					const Percentage =
						assignment.marks && assignment.marks !== 0
							? (reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
									(assignment.marks * (reporting.users.length ?? 1))) *
							  100
							: 'N/A'
					perc.push(Percentage)

					// Passed
					const passed =
						assignment.marks && assignment.marks !== 0
							? reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList?.filter((elem) => {
										if (elem.givenMarks && assignment.marks && assignment.marks !== 0) {
											if (elem.givenMarks / assignment.marks >= 0.5) {
												return 1
											} else {
												return 0
											}
										}
										return 0
									}).length
							: 'N/A'
					pass.push(passed)

					// submitted
					const data = reporting.assignmentSubmissions
						.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
						.submissionList?.filter((elem) => {
							// console.log(assignment.name, elem.submitted)
							if (elem.submitted === true) {
								return 1
							} else {
								return 0
							}
						}).length
					submit.push(data)
				})
				reporting?.quizzes.map((quiz) => {
					name.push(quiz.name)
					const averageMarks =
						reporting.quizSubmissions
							.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
							.submissionList?.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) / (reporting.users.length ?? 1)
					avgMarks.push(averageMarks)

					// minimum quiz marks
					let min = quiz.marks
					min && min !== 0
						? reporting.quizSubmissions
								.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
								.submissionList?.forEach((elem) => {
									if (elem.givenMarks && min && min !== 0) {
										if (elem.givenMarks < min) {
											min = elem.givenMarks
										}
									}
									if (!elem.givenMarks) {
										min = 0
									}
								})
						: 'N/A'
					minMarks.push(min)

					// maximum quiz marks
					let max = 0
					true
						? reporting.quizSubmissions
								.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
								.submissionList?.forEach((elem) => {
									if (elem.givenMarks) {
										if (elem.givenMarks > max) {
											max = elem.givenMarks
										}
									}
								})
						: 'N/A'
					maxMarks.push(max)

					// Percentage
					const Percentage =
						quiz.marks && quiz.marks !== 0
							? (reporting.quizSubmissions
									.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
									.submissionList?.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
									(quiz.marks * (reporting.users.length ?? 1))) *
							  100
							: 'N/A'
					perc.push(Percentage)

					// Passed
					const passed =
						quiz.marks && quiz.marks !== 0
							? reporting.quizSubmissions
									.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
									.submissionList?.filter((elem) => {
										if (elem.givenMarks && quiz.marks && quiz.marks !== 0) {
											if (elem.givenMarks / quiz.marks >= 0.5) {
												return true
											} else {
												return false
											}
										}
										return false
									}).length
							: 'N/A'
					pass.push(passed)

					// submitted
					const data = reporting.quizSubmissions
						.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
						.submissionList?.filter((elem) => {
							if (elem.submitted) {
								return 1
							}
						}).length
					submit.push(data)
				})
				const submitted: number[] = []
				reporting?.assignments.map((assignment) => {
					if (userData) {
						const sub = reporting.assignmentSubmissions
							.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
							.submissionList?.filter((elem, index) => elem.uid === userData.uid)
							?.forEach((elem) => {
								if (elem.submitted) {
									submitted.push(1)
								} else {
									submitted.push(0)
								}
							})
					}
				})
				// const assignmentSubmission = reporting.assignmentSubmissions
				// 	.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
				// 	.submissionList.filter((user) => user.uid === user.uid)?.[0]
				// if (assignmentSubmission.submitted) {
				// 	console.log(`${assignmentSubmission.uid} : Yes`)
				// } else {
				// 	console.log(`${assignmentSubmission.uid} : No`)
				// }

				reporting?.quizzes.map((quiz) => {
					if (userData) {
						reporting.quizSubmissions
							.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
							.submissionList?.filter((user, index) => user.uid === userData.uid)
							?.forEach((elem) => {
								if (elem.submitted) {
									submitted.push(1)
								} else {
									submitted.push(0)
								}
							})
					}
				})

				const scount = submitted.filter((x) => x == 1).length
				const nscount = submitted.filter((x) => x == 0).length
				setTotalsub(scount)
				setTotalnonsub(nscount)
				// console.log('Not ' + nscount)
				// console.log('Sub ' + scount)
				console.log('array of sub ' + submitted)
				setAssignmentName(name)
				setAverage(avgMarks)
				setMaximum(maxMarks)
				setMinimum(minMarks)
				setPercentage(perc)
				setPass(pass)
				setSubmitted(submit)
				setReporting(reporting)
			}
		}
		if (course_id && userData) {
			run()
		}
	}, [course_id, viewAs, userData])

	const data = {
		labels: assigmentName,
		datasets: [
			{
				label: 'Average Marks Assignment',
				backgroundColor: 'rgba(75,192,192,1)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 3,
				data: average,
			},
			{
				label: 'Maximum Marks',
				backgroundColor: 'rgba(43,165,14,7)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 3,
				data: maximum,
			},
			{
				label: 'Minimum Marks',
				backgroundColor: 'rgba(89,109,192,3)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 3,
				data: minimum,
			},
		],
	}

	const data4 = {
		labels: assigmentName,
		datasets: [
			{
				label: 'Submissions',
				backgroundColor: 'rgba(89,56,100,90)',
				data: submit,
			},
		],
	}

	const data3 = {
		labels: assigmentName,
		datasets: [
			{
				label: 'Pass',
				backgroundColor: 'rgba(89,19,12,30)',
				data: pass,
			},
		],
	}

	const data2 = {
		labels: assigmentName,
		datasets: [
			{
				label: 'Percentage',
				backgroundColor: 'rgba(75,192,192,1)',
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 3,
				data: percentage,
			},
		],
	}
	const data5 = {
		labels: ['Submitted', 'Not Submitted'],
		datasets: [
			{
				label: 'Submitted',
				backgroundColor: ['rgb(0,255,127)', 'rgb(250,128,114)'],
				borderColor: 'rgba(0,0,0,1)',
				borderWidth: 1,
				data: [scount, nscount],
				hoverOffset: 4,
			},
		],
	}

	// const data5 = {
	// 	labels: ['Submitted', 'Not Submitted'],
	// 	datasets: [
	// 		{
	// 			label: 'Submitted',
	// 			data: valueOrDefault.numbers({ count: scount, min: 0, max: 100 }),
	// 			backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
	// 			hoverOffset: 4,
	// 		},
	// 	],
	// }

	// const state_maximumMarks = {
	// 	labels: assigmentName,
	// 	datasets: [
	// 		{
	// 			label: 'Maximum Marks',
	// 			backgroundColor: 'rgba(43,165,14,7)',
	// 			borderColor: 'rgba(0,0,0,1)',
	// 			borderWidth: 3,
	// 			data: maximum,
	// 		},
	// 	],
	// }

	// const state_minimumMarks = {
	// 	labels: assigmentName,
	// 	datasets: [
	// 		{
	// 			label: 'Minimum Marks',
	// 			backgroundColor: 'rgba(89,109,192,3)',
	// 			borderColor: 'rgba(0,0,0,1)',
	// 			borderWidth: 3,
	// 			data: minimum,
	// 		},
	// 	],
	// }

	return (
		<>
			{ShowDetails}
			<hr />
			{viewAs === 'Teacher' && (
				<div>
					{'Total Number of students: '}
					{totalStudent}
				</div>
			)}
			<table className='table'>
				<thead>
					<tr>
						<th scope='col'>Name</th>
						<th scope='col'>Roll number</th>
						{reporting?.assignments.map((assignment) => (
							<>
								<th scope='col'>
									<Link to={`./asset/${assignment.uid}`}>{assignment.name}</Link>
								</th>
							</>
						))}
						{reporting?.quizzes.map((quiz) => (
							<>
								<th scope='col'>
									<Link to={`./asset/${quiz.uid}`}>{quiz.name}</Link>
								</th>
							</>
						))}
					</tr>
				</thead>
				<thead>
					<tr>
						<th scope='col'></th>
						<th scope='col'></th>
						{reporting?.assignments.map(() => (
							<>
								<th style={{ minWidth: '200px' }} scope='col'>
									{'Assignment'}
								</th>
							</>
						))}
						{reporting?.quizzes.map(() => (
							<>
								<th style={{ minWidth: '200px' }} scope='col'>
									{'Quiz'}
								</th>
							</>
						))}
					</tr>
				</thead>
				<tbody>
					{viewAs === 'Teacher' &&
						reporting?.users.map((user_) => {
							return (
								<>
									<tr>
										<td scope='row'>
											{user_.user.first_name} {user_.user.last_name}
										</td>
										<td scope='row'>{user_.user.roll_num}</td>
										{reporting?.assignments.map((assignment) => {
											const assignmentSubmission = reporting.assignmentSubmissions
												.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
												.submissionList.filter((user) => user.uid === user_.user.uid)?.[0]
											return (
												<>
													<td scope='col'>
														{assignment.grade_type === 'grades'
															? assignmentSubmission.grades
															: assignmentSubmission.givenMarks ?? 0}
													</td>
												</>
											)
										})}
										{reporting?.quizzes.map((quiz) => (
											<>
												<td scope='col'>
													{reporting.quizSubmissions
														.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
														.submissionList.filter((user) => user.uid === user_.user.uid)?.[0].givenMarks ?? 0}
												</td>
											</>
										))}
									</tr>
								</>
							)
						})}
					{viewAs === 'Student' &&
						reporting?.users
							.filter((elem) => elem.user.uid === userData?.uid)
							.map((user_) => {
								return (
									<>
										<tr>
											<td scope='row'>
												{user_.user.first_name} {user_.user.last_name}
											</td>
											<td scope='row'>{user_.user.roll_num}</td>
											{reporting?.assignments.map((assignment) => {
												const assignmentSubmission = reporting.assignmentSubmissions
													.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
													.submissionList.filter((user) => user.uid === user_.user.uid)?.[0]
												return (
													<>
														<td scope='col'>
															{assignment.grade_type === 'grades'
																? assignmentSubmission.grades
																: assignmentSubmission.givenMarks ?? 0}
														</td>
													</>
												)
											})}
											{reporting?.quizzes.map((quiz) => (
												<>
													<td scope='col'>
														{reporting.quizSubmissions
															.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
															.submissionList.filter((user) => user.uid === user_.user.uid)?.[0].givenMarks ?? 0}
													</td>
												</>
											))}
										</tr>
									</>
								)
							})}
					<tr>
						<td scope='row'>{'Averages'}</td>
						<td scope='row'></td>
						{reporting?.assignments.map((assignment) => {
							const averageMarks =
								reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) / (totalStudent ?? 1)
							return (
								<>
									<td scope='col'>{Math.round(averageMarks).toFixed(2)}</td>
								</>
							)
						})}
						{reporting?.quizzes.map((quiz) => (
							<>
								<td scope='col'>
									{reporting.quizSubmissions
										.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
										.submissionList?.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) / (totalStudent ?? 1)}
								</td>
							</>
						))}
					</tr>

					<tr>
						<td scope='row'>{viewAs === 'Teacher' ? 'Percentage' : '% of Marks Scored'}</td>
						<td scope='row'></td>
						{viewAs === 'Teacher' &&
							reporting?.assignments.map((assignment) => {
								const percentageMarks =
									assignment.marks && assignment.marks !== 0
										? (reporting.assignmentSubmissions
												.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
												.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
												(assignment.marks * (totalStudent ?? 1))) *
										  100
										: 'N/A'
								return (
									<>
										<td scope='col'>{percentageMarks !== 'N/A' ? Math.round(percentageMarks).toFixed(2) : 'N/A'} %</td>
									</>
								)
							})}
						{viewAs === 'Student' &&
							reporting?.assignments.map((assignment) => {
								const percentageMarks =
									assignment.marks && assignment.marks !== 0
										? (reporting.assignmentSubmissions
												.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
												.submissionList.filter((elem) => elem.uid === userData?.uid)
												.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
												assignment.marks) *
										  100
										: 'N/A'
								return (
									<>
										<td scope='col'>{percentageMarks !== 'N/A' ? Math.round(percentageMarks).toFixed(2) : 'N/A'} %</td>
									</>
								)
							})}
						{viewAs === 'Teacher' &&
							reporting?.quizzes.map((quiz) => {
								const percentageMarks =
									quiz.marks && quiz.marks !== 0
										? (reporting.quizSubmissions
												.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
												.submissionList?.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
												(quiz.marks * (totalStudent ?? 1))) *
										  100
										: 'N/A'
								return (
									<>
										<td scope='col'>{percentageMarks !== 'N/A' ? Math.round(percentageMarks).toFixed(2) : 'N/A'} %</td>
									</>
								)
							})}
						{viewAs === 'Student' &&
							reporting?.quizzes.map((quiz) => {
								const percentageMarks =
									quiz.marks && quiz.marks !== 0
										? (reporting.quizSubmissions
												.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
												.submissionList?.filter((elem) => elem.uid === userData?.uid)
												.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
												quiz.marks) *
										  100
										: 'N/A'
								return (
									<>
										<td scope='col'>{percentageMarks !== 'N/A' ? Math.round(percentageMarks).toFixed(2) : 'N/A'} %</td>
									</>
								)
							})}
					</tr>
					{viewAs === 'Teacher' && (
						<tr>
							<td scope='row'>{'Passed (more than 50%)'}</td>
							<td scope='row'></td>
							{reporting?.assignments.map((assignment) => {
								const passed =
									assignment.marks && assignment.marks !== 0
										? reporting.assignmentSubmissions
												.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
												.submissionList?.filter((elem) => {
													if (elem.givenMarks && assignment.marks && assignment.marks !== 0) {
														if (elem.givenMarks / assignment.marks >= 0.5) {
															return true
														} else {
															return false
														}
													}
													return false
												}).length
										: // .reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
										  // (assignment.marks * (reporting.users.length ?? 1))) *
										  //   100
										  'N/A'
								return (
									<>
										<td scope='col'>
											{passed} / {totalStudent}
										</td>
									</>
								)
							})}
							{reporting?.quizzes.map((quiz) => {
								const passed =
									quiz.marks && quiz.marks !== 0
										? reporting.quizSubmissions
												.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
												.submissionList?.filter((elem) => {
													if (elem.givenMarks && quiz.marks && quiz.marks !== 0) {
														if (elem.givenMarks / quiz.marks >= 0.5) {
															return true
														} else {
															return false
														}
													}
													return false
												}).length
										: 'N/A'
								return (
									<>
										<td scope='col'>
											{passed} / {totalStudent}
										</td>
									</>
								)
							})}
						</tr>
					)}
					<tr>
						<td scope='row'>{'Min'}</td>
						<td scope='row'></td>
						{reporting?.assignments.map((assignment) => {
							let min = assignment.marks
							if (assignment.grade_type === 'grades') {
								min = 0
							} else {
								min && min !== 0
									? reporting.assignmentSubmissions
											.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
											.submissionList?.forEach((elem) => {
												// console.log(assignment.name, elem.givenMarks)
												if (elem.givenMarks && min && min !== 0) {
													if (elem.givenMarks < min) {
														min = elem.givenMarks
													}
												}
												if (!elem.givenMarks) {
													min = 0
												}
											})
									: // .reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
									  // (assignment.marks * (reporting.users.length ?? 1))) *
									  //   100
									  'N/A'
							}
							return (
								<>
									<td style={min !== 0 ? { fontWeight: 'bold' } : {}} scope='col'>
										{min}
									</td>
								</>
							)
						})}
						{reporting?.quizzes.map((quiz) => {
							let min = quiz.marks
							min && min !== 0
								? reporting.quizSubmissions
										.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
										.submissionList?.forEach((elem) => {
											if (elem.givenMarks && min && min !== 0) {
												if (elem.givenMarks < min) {
													min = elem.givenMarks
												}
											}
											if (!elem.givenMarks) {
												min = 0
											}
										})
								: 'N/A'
							return (
								<>
									<td style={min !== 0 ? { fontWeight: 'bold' } : {}} scope='col'>
										{min}
									</td>
								</>
							)
						})}
					</tr>

					<tr>
						<td scope='row'>{'Max'}</td>
						<td scope='row'></td>
						{reporting?.assignments.map((assignment) => {
							let max = 0

							if (assignment.grade_type === 'grades') {
								max = 0
							} else {
								true
									? reporting.assignmentSubmissions
											.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
											.submissionList?.forEach((elem) => {
												// console.log(assignment.name, elem.givenMarks)
												if (elem.givenMarks) {
													if (elem.givenMarks >= max) {
														max = elem.givenMarks
													}
												}
											})
									: 'N/A'
							}
							return (
								<>
									<td style={max !== 0 ? { fontWeight: 'bold' } : {}} scope='col'>
										{max}
									</td>
								</>
							)
						})}
						{reporting?.quizzes.map((quiz) => {
							let max = 0
							true
								? reporting.quizSubmissions
										.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
										.submissionList?.forEach((elem) => {
											if (elem.givenMarks) {
												if (elem.givenMarks > max) {
													max = elem.givenMarks
												}
											}
										})
								: 'N/A'
							return (
								<>
									<td style={max !== 0 ? { fontWeight: 'bold' } : {}} scope='col'>
										{max}
									</td>
								</>
							)
						})}
					</tr>

					{viewAs === 'Student' && (
						<tr>
							<td scope='row'>{'Submitted'}</td>
							<td scope='row'></td>
							{reporting?.users
								.filter((elem) => elem.user.uid === userData?.uid)
								.map((user_) => {
									return (
										<>
											{/* <tr> */}
											{reporting?.assignments.map((assignment) => {
												const assignmentSubmission = reporting.assignmentSubmissions
													.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
													.submissionList.filter((user) => user.uid === user_.user.uid)?.[0]
												return (
													<>
														<td scope='col'>
															{assignmentSubmission.submitted && `Yes`}
															{assignmentSubmission.submitted === false && `No`}
														</td>
													</>
												)
											})}
											{reporting?.quizzes.map((quiz) => (
												<>
													<td scope='col'>
														{reporting.quizSubmissions
															.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
															.submissionList.filter((user) => user.uid === user_.user.uid)?.[0].submitted
															? 'Yes'
															: 'No'}
													</td>
												</>
											))}
											{/* </tr> */}
										</>
									)
								})}
						</tr>
					)}
					{/* {viewAs === 'Student' && (
						<tr>
							<td scope='row'>{'Passed'}</td>
							{reporting?.users.map((user_) => {
								return (
									<>
										{reporting?.assignments.map((assignment) => {
											const pass =
												reporting.assignmentSubmissions
													.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
													.submissionList.reduce((a, b) => a + (b?.givenMarks ?? 0), 0) /
													(assignment.marks * (totalStudent ?? 1))) *
											  100
													.submissionList.filter((user) => user.uid === user_.user.uid)?.[0].givenMarks ?? 0
											

											return <> {pass}</>
										})}
										{/* {/* {reporting?.quizzes.map((quiz) => (
											<>
												<td scope='col'>
													{reporting.quizSubmissions
														.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
														.submissionList.filter((user) => user.uid === user_.user.uid)?.[0].submitted
														? 'Yes'
														: 'No'}
												</td>
											</>
										))} */}
					{/* </>  */}
					{/* )
							})}
						</tr>
					)} */}

					{viewAs === 'Teacher' && (
						<tr>
							<td scope='row'>{'Submitted'}</td>
							<td scope='row'></td>
							{reporting?.assignments.map((assignment) => {
								const data = reporting.assignmentSubmissions
									.filter((assignmentSubmission) => assignmentSubmission.uid === assignment.uid)?.[0]
									.submissionList?.filter((elem) => {
										// console.log(assignment.name, elem.submitted)
										if (elem.submitted === true) {
											return true
										} else {
											return false
										}
									})

								return (
									<>
										<td style={data?.length !== 0 ? { fontWeight: 'bold' } : {}} scope='col'>
											{data?.length}/{totalStudent}
										</td>
									</>
								)
							})}
							{reporting?.quizzes.map((quiz) => {
								const data = reporting.quizSubmissions
									.filter((quizSubmission) => quizSubmission.uid === quiz.uid)?.[0]
									.submissionList?.filter((elem) => {
										if (elem.submitted) {
											return true
										}
									})
								return (
									<>
										<td style={data?.length !== 0 ? { fontWeight: 'bold' } : {}} scope='col'>
											{data?.length}/{totalStudent}
										</td>
									</>
								)
							})}
						</tr>
					)}
				</tbody>
			</table>

			{/* <div>
					<Bar data={state_maximumMarks} />
				</div>
				<div>
					<Bar data={state_minimumMarks} />
				</div> */}
			{viewAs === 'Teacher' && (
				<div className='row'>
					<div className='col-sm-12 col-md-6'>
						<Bar data={data} />
					</div>

					<div className='col-sm-12 col-md-6'>
						<Bar data={data2} />
					</div>
					<div className='col-sm-12 col-md-6'>
						<Bar data={data3} />
					</div>
					<div className='col-sm-12 col-md-6'>
						<Bar data={data4} />
					</div>
				</div>
			)}
			{viewAs === 'Student' && (
				<div className='row'>
					<div className='col-sm-12 col-md-6'>
						<Bar data={data} />
					</div>
					<div className='col-sm-12 col-md-6'>
						<Pie style={{ height: '200px' }} data={data5} />
					</div>
				</div>
			)}
		</>
	)
}
export default Reporting
